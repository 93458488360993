<template lang="pug">
    modal(name="face-swap-choose-photo" class="face-swap-choose-photo-modal-wrap" :adaptive="true" width="100%" height="100%" @before-open="beforeOpen" @before-close="beforeClose")
        perfect-scrollbar.face-swap-choose-photo-modal
            ._wrap
                ._content
                    ._content-inner
                        ._info
                            loading._is-loading(v-if="isLoading")
                            ._close-btn(@click="$modal.hide('face-swap-choose-photo')")
                                svg-icon(icon-name="cross")._close-icon
                            ._title Выберите фото, с которого берём лицо
                            ._empty(v-if="!gallery.length") У Вас не загружено ни одной фотографии
                            ._list-box(v-else)
                                ._list
                                    ._photo(v-for="photo in gallery" v-if="photo.__typename === 'GalleyPhoto'")
                                        ._photo-box
                                            user-photo(:photo="photo.photo" :micro="false" :thumbs="true")._photo-avatar
                                            ._set-photo(v-if="photo.allow_delete" @click="setAvatar(photo.id)") Сделать основной
                                            ._set-photo.-active(v-else) Основной аватар
                                            ._remove-photo(@click="confirmDeletePhoto(photo)" v-if="photo.allow_delete")
                                                svg-icon(icon-name="cross")._remove-icon
                                        ui-button(type="bordered" color="gray" text="Выбрать для Face Swap" @click.native="choose(photo.photo)")._choose-btn
                            ._photo.-full
                                change-photos(:show-gift="false" :hide-expample="true" photoType="avatar" :success-text="locale('photo-uploaded')" @photo-uploaded="getGallery")._choose-photo
                            //._attemption
                            //    svg-icon(icon-name="assistant/attempt")._attemption-icon
                            //    ._attemption-info
                            //        ._attemption-title Внимание!
                            //        ._attemption-description Фотография, которую Вы выберите основной - будет установлена в качестве аватарки на Вашей личной странице и её смогут увидеть другие пользователи
</template>

<script>
import UiButton from "../../ui/ui-button/ui-button.vue";
import UserPhoto from "@/components/ui/user-photo/user-photo.vue";
import gql from "graphql-tag";
import getPhotoGalleryQuery from "@/graphql/queries/getPhotoGallery.query.graphql";
import ChangePhotos from "@/components/person/change-photos/change-photos.vue";
import setAvatarFromGalleryMutation from "@/graphql/mutations/setAvatarFromGallery.mutation.graphql";
import deletePhotoFromGalleryMutation from "@/graphql/mutations/deletePhotoFromGallery.mutation.graphql";

export default {
    name: "FaceSwapChoosePhotoModal",
    components: { ChangePhotos, UserPhoto, UiButton },
    data() {
        return {
            gallery: [],
            photoToDelete: null,
            isLoading: true
        };
    },
    computed: {},
    methods: {
        beforeOpen() {
            this.getGallery();
        },
        beforeClose() {},
        choose(photo) {
            this.$store.dispatch("set", {
                name: "faceSwapPhoto",
                value: photo,
            });
            this.$modal.hide("face-swap-choose-photo");
        },
        async getGallery() {
            this.isLoading = true;
            this.gallery = [];
            /*            if (this.myPerson.photos.anfas) {
                            this.gallery.push({
                                photo: this.myPerson.photos.anfas,
                            });
                        }*/
            await this.$apollo
                .query({
                    query: gql(getPhotoGalleryQuery),
                    fetchPolicy: "no-cache",
                })
                .then((r) => {
                    const gallery = r.data.getPhotoGallery;
                    console.log("ga", gallery);

                    this.$emit("change-avatar-count", gallery.length);

                    if (!gallery.length) {
                        this.gallery = [];
                        setTimeout(() => {
                            this.isLoading = false;
                        }, 1000);
                        return;
                    }
                    const active = gallery.find((p) => !p.allow_delete);
                    gallery.splice(gallery.indexOf(active), 1);
                    gallery.unshift(active);
                    this.gallery.push(...gallery);
                    this.gallery = gallery.filter(item => item && item.photo);
                    setTimeout(() => {
                        this.isLoading = false;
                    }, 1000);
                });
        },
        async setAvatar(id) {
            await this.$apollo
                .mutate({
                    mutation: gql(setAvatarFromGalleryMutation),
                    variables: {
                        id: id,
                    },
                })
                .then(() => {
                    this.getGallery();
                });
        },
        confirmDeletePhoto(photo) {
            this.photoToDelete = photo;
            this.$store.dispatch("changeConfirmDialog", {
                show: true,
                loading: false,
                title: "Подтвердите действие",
                text: "Вы действительно хотите удалить фотографию? Отменить данное действие невозможно",
                buttons: true,
                confirmText: "Удалить",
                callback: this.deletePhoto,
            });
        },
        async deletePhoto() {
            await this.$apollo
                .mutate({
                    mutation: gql(deletePhotoFromGalleryMutation),
                    variables: {
                        id: this.photoToDelete.id,
                    },
                })
                .then(() => {
                    this.getGallery();
                    this.$emit("photo-deleted");
                });
        },
    },
};
</script>

<style lang="scss" src="./face-swap-choose-photo.scss"></style>
