import Vue from "vue";
import { DateTime } from "luxon";
import gql from "graphql-tag";
import getMyUserQuery from "@/graphql/queries/getMyUser.query.graphql";
import onBoardingGetCompletedStepsQuery from "@/graphql/queries/onBoardingGetCompletedSteps.query.graphql";
import onBoardingGetStepQuery from "@/graphql/queries/onBoardingGetStep.query.graphql";
import getAdvertQuery from "@/graphql/queries/getAdvert.query.graphql";
import newUserPhoneQuery from "@/graphql/queries/newUserPhone.query.graphql";
import newUserPhoneVerifyMutation from "@/graphql/mutations/newUserPhoneVerify.mutation.graphql";
import taskGetQuery from "@/graphql/queries/taskGet.query.graphql";

const MAX_GET_USER_ATTEMPTS = 10;
let GET_USER_ATTEMPTS = 0;

export default Vue.mixin({
    data() {
        return {
            IS_DEVELOPMENT: false,
            MERLIN_CONVERSATION_ID: "18d68acc-b1cd-11ed-b5e6-02420a000cf6",
            NO_AVATAR_USER: require("../assets/images/no-avatar.jpg"),
            welcomePsychotypePaths: {
                1: "/welcome/want",
                2: "/welcome/name",
                3: "/welcome/personal-info",
                4: "/welcome/personal-info",
                5: "/welcome/anfas",
                6: "/welcome/anfas",
                7: "/welcome/psychotype-pay",
                8: "/welcome/test",
                9: "/welcome/photo-test",
                10: "/welcome/test-complete",
                11: "/welcome/test-complete",
                12: "/",
            },
            welcomeBusinessCardPaths: {
                1: "/welcome/want",
                2: "/welcome/name",
                3: "/welcome/personal-info",
                4: "/welcome/personal-info",
                5: "/welcome/anfas",
                6: "/welcome/anfas",
                7: "/welcome/business-card-pay",
                8: "/welcome/test",
                9: "/welcome/photo-test",
                10: "/welcome/business-card-info",
                11: "/welcome/business-card-info",
                12: "/",
            },
            welcomeProfPaths: {
                1: "/welcome/want",
                2: "/welcome/start",
                3: "/welcome/personal-info",
                4: "/welcome/personal-info",
                5: "/welcome/anfas",
                6: "/welcome/anfas",
                7: "/welcome/prof-pay",
                8: "/welcome/test",
                9: "/welcome/photo-test",
                10: "/welcome/analyze-ready",
                11: "/welcome/prof-pay",
                12: "/welcome/professions",
            },
        };
    },
    computed: {
        SUPPORT_LINK: function () {
            let link = "https://t.me/merlin_support";
            const notFreeSubscriptions = this.MY_SUBSCRIPTIONS.filter(
                (sub) => sub.level !== "FREE",
            );

            if (notFreeSubscriptions.length) {
                link = "https://t.me/MerlinFace_priority";
            }

            return link;
        },
        IS_FACE_SWAP_NEED_PHOTOS: function () {
            if (this.myPerson.photos.anfas) {
                return this.myPerson.photos.anfas.indexOf("dummy_anfas") !== -1;
            }
            return true;
        },
        IS_FACE_SWAP_NEED_PLAN: function () {
            if (this.myUser.is_welcome_credit_active) {
                return false;
            }
            const plans = this.MY_SUBSCRIPTIONS.filter(
                (plan) =>
                    +plan.id === 26 ||
                    +plan.id === 27 ||
                    +plan.id === 32 ||
                    +plan.id === 33,
            );

            return !plans.length;
        },
        MY_SUBSCRIPTIONS: (state) =>
            state.$store.getters["user/mySubscriptions"],
        IS_NEURAL_FREE: (state) =>
            state.MY_SUBSCRIPTIONS.filter((plan) => +plan.id === 31).length,
        isUserAuth: function () {
            return this.$store.getters["auth/authStatus"];
        },
        isMyProfile: function () {
            if (this.$route.name === "public_person") {
                return (
                    this.$route.params.uuid ===
                    this.$store.getters["user/public_url"]
                );
            }
            return this.$route.params.uuid === this.$store.getters["user/uuid"];
        },
        myUser: function () {
            return this.$store.getters["user/info"];
        },
        myPerson: function () {
            return this.$store.getters["user/person"];
        },
        myRoles: function () {
            return this.$store.getters["user/myUserRoles"];
        },
        isAdmin: function () {
            return !!(
                this.myRoles &&
                this.$inArray(this.myRoles, ["ROLE_ADMIN"]) === true
            );
        },
        isCurator: function () {
            return !!(
                this.myRoles &&
                this.$inArray(this.myRoles, ["ROLE_CURATOR"]) === true
            );
        },
        isEditor: function () {
            return !!(
                this.myRoles &&
                this.$inArray(this.myRoles, ["ROLE_ADMIN", "ROLE_EDITOR"]) ===
                    true
            );
        },
        isCoach: function () {
            return (
                this.myRoles &&
                this.$inArray(this.myRoles, ["ROLE_COACH"]) === true
            );
        },
        isDesktopView: function () {
            return this.pageWidth > 991;
        },
        isMobileView: function () {
            return this.pageWidth <= 991;
        },
        noRealPhotos: (state) =>
            !state.myPerson.extras.external_id ||
            state.myPerson.extras.external_id === "405534",
        haveRealPhotos: (state) =>
            state.myPerson.extras.external_id &&
            state.myPerson.extras.external_id !== "405534",
        isPersonCelebrity: (state) =>
            state.$store.getters["person/isCelebrity"],
        isCelebrity: (state) => state.$store.getters["user/celebrity"],
        personExternalIdPresent: (state) =>
            state.$store.getters["person/externalIdPresent"],
        userExternalIdPresent: (state) =>
            state.$store.getters["user/externalIdPresent"],
        onBoardingCompleted: (state) => state.myUser.on_boarding_completed,
        interfaceAvailable: (state) =>
            state.isUserAuth && state.onBoardingCompleted,
        pageWidth: (state) => state.$store.getters["windowWidth"],
        pageName: (state) => state.$route.name,
        currentPerson: (state) => state.$store.getters["person/info"],
    },
    mounted() {
        this.MERLIN_CONVERSATION_ID = process.env.MERLIN_CONVERSATION_ID
            ? process.env.MERLIN_CONVERSATION_ID
            : this.MERLIN_CONVERSATION_ID;

        if (
            process.env.NODE_ENV === "development" ||
            localStorage.getItem("mode") === "development"
        ) {
            this.IS_DEVELOPMENT = true;
        }
    },
    methods: {
        async CHECK_TASK_STATUS(taskId) {
            return await this.$apollo
                .query({
                    query: gql(taskGetQuery),
                    variables: {
                        taskId,
                    },
                })
                .then(async (r) => {
                    return r.data.taskGet;
                });
        },
        locale(v, props = {}, global = false) {
            if (global) return this.$t(v, props);
            return this.$t(`${this.$options.name}.${v}`, props);
        },
        localeError(error) {
            return this.$t(`errors.${error}`);
        },
        localeSubscriptions(v) {
            return this.$t(`subscriptionsData.${v}`);
        },
        async loadMyUser(firstAuth = false) {
            console.group("common.mixin.js:loadMyUser");
            await this.$apollo
                .query({
                    query: gql`
                        ${getMyUserQuery}
                    `,
                    fetchPolicy: "no-cache",
                })
                .then(async (r) => {
                    const user = r.data.getMyUser;
                    if (user.uuid) {
                        console.log("save auth user data", user);
                        await this.$store.dispatch("user/setMyUserInfo", user);
                    }
                    await this.redirectController(firstAuth);
                })
                .catch((e) => {
                    if (e.graphQLErrors) {
                        const errorCode = e.graphQLErrors[0].extensions.code;
                        if (errorCode === 1001) {
                            this.$store.dispatch("auth/signout");
                            this.$router.push({ name: "Login" });
                            this.$router.go(1);
                        }
                        if (errorCode === 1002) {
                            if (MAX_GET_USER_ATTEMPTS >= GET_USER_ATTEMPTS++) {
                                console.log("get", GET_USER_ATTEMPTS);
                                setTimeout(() => {
                                    this.loadMyUser(firstAuth);
                                }, 1000);
                            } else {
                                GET_USER_ATTEMPTS = 0;
                                this.$store.dispatch("auth/signout");
                                this.$router.push("/");
                                this.$router.go();
                                this.$msgBox(
                                    "Профиль не найден",
                                    "Ваш профиль не был найден в нашей базе данных. Пожалуйста, обратитесь в службу поддержки.",
                                );
                            }
                        }
                    }
                });
            console.groupEnd();
        },
        async redirectController(auth = false) {
            console.group("redirect controller");
            const onBoardingStatus = this.myUser.on_boarding_completed;
            const redirect = localStorage.redirect;
            const personPublicUrl = this.myPerson.public_url;
            const personUuid = this.myPerson.uuid;

            // Если юзер прошёл онбординг
            if (onBoardingStatus) {
                if (this.myRoles.indexOf("ROLE_NO_PHONE") >= 0) {
                    return this.$router.push("/welcome/phone");
                }

                if (this.myUser.display_name === "Newbie") {
                    if (this.$route.fullPath !== "/welcome/name") {
                        return this.$router.push("/welcome/name");
                    }
                    return;
                }

                if (this.noRealPhotos && auth) {
                    if (!this.$route.fullPath.includes("/messages")) {
                        this.$router.push(`/messages/${this.MERLIN_CONVERSATION_ID}`);
                    }
                    return;
                }

                // Если есть редирект и юзер не на странице редиректа - редиректим его
                if (redirect && this.$route.fullPath !== redirect) {
                    localStorage.redirect = "";
                    return this.$router.push(redirect);
                }

                if (auth) {
                    // if (personPublicUrl) {
                    //     if (this.$route.fullPath !== `/${personPublicUrl}`) {
                    //         console.log("red10");
                    //         return this.$router.push(`/${personPublicUrl}`);
                    //     }
                    // }
                    // else{
                    this.$router.push(`/messages/${this.MERLIN_CONVERSATION_ID}`);
                    // }

                    // if (this.$route.fullPath !== `/person/${personUuid}`) {
                    //     // return this.$router.push(`/person/${personUuid}`);
                    //     return this.$router.push(`/messages/${this.MERLIN_CONVERSATION_ID}`);
                    // }
                }

                if (this.$route.name === "home") {
                    if (personPublicUrl) {
                        if (this.$route.fullPath !== `/${personPublicUrl}`) {
                            return this.$router.push(`/${personPublicUrl}`);
                        }
                    }
                    if (this.$route.fullPath !== `/person/${personUuid}`) {
                        return this.$router.push(`/person/${personUuid}`);
                    }
                }
            } else {
                const completedSteps = await this.getOnBoardingCompletedSteps();
                const funnelType =
                    completedSteps.find((step) => step.step_number === 1) || 0;

                let lastStepIndex = 0;
                let steps = this.welcomeProfPaths;

                if (funnelType.step_result_int) {
                    if (funnelType.step_result_int === 61) {
                        steps = this.welcomePsychotypePaths;
                    }
                    if (funnelType.step_result_int === 62) {
                        steps = this.welcomeBusinessCardPaths;
                    }
                }

                if (completedSteps.length) {
                    let foundedStep = {};
                    let found = false;
                    //let skippedSteps = localStorage["skip-steps"]
                    //    ? JSON.parse(localStorage["skip-steps"])
                    //    : [];

                    completedSteps.forEach((step, index) => {
                        if (index === 0) {
                            foundedStep = step;
                        }
                        if (found) return;

                        //&& skippedSteps.indexOf(foundedStep.step_number + 1) === -1
                        if (step.step_number - foundedStep.step_number > 1) {
                            lastStepIndex = foundedStep.step_number;
                            found = true;
                        } else {
                            lastStepIndex = step.step_number;
                            foundedStep = step;
                        }
                    });
                }

                console.log("lastStepIndex_________________", lastStepIndex);

                if (
                    funnelType.step_result_int === 60 &&
                    lastStepIndex === 11
                ) {
                    console.log("Проверяем сколько раундов осталось");
                    const professionVoteRoundsLeft = completedSteps.filter(
                        (step) => step.step_number === 10,
                    );
                    let voteEnd = false;
                    
                    professionVoteRoundsLeft.forEach((step) => {
                        if (!step?.step_result_json.roundsLeft) {
                            voteEnd = true;
                        }
                    });

                    if (!voteEnd) {
                        lastStepIndex = 9;
                    }
                }

                let nextStepPath = steps[lastStepIndex + 1];

                await this.$store.dispatch("set", {
                    name: "nextOnboardingStepIndex",
                    value: lastStepIndex + 1,
                });

                console.log(
                    "Next on boarding step",
                    lastStepIndex + 1,
                    completedSteps,
                );

                const availablePassTypes = [
                    "PROFESSION_ORIENTATION",
                    "PSYCHOTIC_DETECTION",
                    "BUSINESS_CARD",
                ];
                if (
                    availablePassTypes.indexOf(
                        this.$route.query["shp_ObPassType"],
                    ) >= 0
                ) {
                    nextStepPath = "/welcome/pay-check";
                }
                //// Если при инициализации следующим шагом должен быть шаг с заполнением имени
                //// нужно проверить, возможно имя уже указано, тогда мы пропускаем этот шаг
                //if (nextStepPath === '/welcome/start' && this.myUser.display_name !== "Newbie") {
                //    nextStepPath = '/welcome/personal-info'
                //}

                // Если при инициализации следующим шагом должен быть шаг с фото тестом
                // нужно проверить, возможно в тесте остались незавершённые вопросы
                if (nextStepPath === "/welcome/photo-test") {
                    const moreTest = await this.checkOnBoardingTestComplete();
                    if (moreTest) {
                        nextStepPath = "/welcome/test";
                    }
                }

                console.log("Next on boarding path", nextStepPath);

                if (this.$route.fullPath !== nextStepPath) {
                    await this.$router.push(nextStepPath);
                }
            }
            console.groupEnd();
        },
        async temporaryAuth(test = true) {
            if (test) {
                return await this.$router.push("/welcome/login");
            }

            const phoneNumber = Math.floor(Math.random() * 100000000);

            await this.$apollo
                .query({
                    query: gql(newUserPhoneQuery),
                    fetchPolicy: "no-cache",
                    variables: {
                        phoneNumber: `+888${phoneNumber}`,
                        authType: "",
                    },
                })
                .then(async (r) => {
                    if (r.data.newUserPhone.wait) {
                        this.$msgBox(
                            "Ошибка",
                            `Для Вашего IP-адреса истекло количество попыток авторизации, повторите попытку через ${r.data.newUserPhone.wait} сек.`,
                        );
                        return;
                    }

                    this.$emit("landing-loading");
                    const phone = r.data.newUserPhone.phone;
                    const confirmationCode = +phone.slice(-4);

                    await this.$apollo
                        .mutate({
                            mutation: gql(newUserPhoneVerifyMutation),
                            variables: {
                                phoneNumber: phone,
                                confirmationCode: confirmationCode,
                                promoCode: this.promocode,
                            },
                        })
                        .then(async (r) => {
                            const responseData = r.data.newUserPhoneVerify;
                            await this.$store.dispatch("auth/successAuth", {
                                jwt: responseData.jwt,
                                refresh_token: responseData.refresh_token,
                                user_id: responseData.user_uuid,
                            });
                            await this.loadMyUser(true);
                        });
                });
        },
        async getOnBoardingCompletedSteps() {
            return await this.$apollo
                .query({
                    query: gql`
                        ${onBoardingGetCompletedStepsQuery}
                    `,
                    fetchPolicy: "no-cache",
                })
                .then((r) => {
                    return r.data.onBoardingGetCompletedSteps.sort((a, b) =>
                        a.step_number > b.step_number ? 1 : -1,
                    );
                });
        },
        async checkOnBoardingTestComplete() {
            return await this.$apollo
                .query({
                    query: gql`
                        ${onBoardingGetStepQuery}
                    `,
                    variables: {
                        step: 8,
                    },
                    fetchPolicy: "no-cache",
                })
                .then((r) => {
                    return r.data.onBoardingGetStep.test?.length || 0;
                });
        },
        getRemainingTime: function (endTime = false) {
            if (!endTime) return;
            let formattedEndTime = endTime.date
                .replace(/-/g, "/")
                .replace(/.000000/g, "");
            let date =
                    DateTime.fromJSDate(new Date(formattedEndTime)).setZone(
                        endTime.timezone,
                        { keepLocalTime: true },
                    ) - DateTime.now(),
                days = Math.floor(date / 1000 / 60 / 60 / 24),
                hours = Math.floor((date / 1000 / 60 / 60) % 24),
                minutes = Math.floor((date / 1000 / 60) % 60),
                seconds = Math.floor((date / 1000) % 60),
                endDate = "";

            if (hours < 10 && hours > 0) {
                hours = `0${hours}`;
            }

            if (minutes < 10) {
                minutes = `0${minutes}`;
            }

            if (seconds < 10) {
                seconds = `0${seconds}`;
            }

            if (days > 0) {
                endDate = `${days} ${this.locale("days", {}, true)}, `;
            }

            if (hours) {
                endDate += `${hours}:`;
            }

            endDate += `${minutes}:${seconds}`;

            return endDate;
        },
        calcAge(dateOfBirth, noDate = "") {
            if (!dateOfBirth) return noDate;

            let date = DateTime.fromJSDate(new Date(dateOfBirth));
            return Math.floor(Math.abs(date.diffNow().as("year")));
        },
        formatDate(timestamp, format) {
            const date = new Date(timestamp);

            // Extract the date components in UTC
            const day = date.getUTCDate().toString().padStart(2, "0");
            const month = (date.getUTCMonth() + 1).toString().padStart(2, "0"); // Months are 0-based
            const year = date.getUTCFullYear();

            // Replace the format with actual values
            return format.replace("dd", day).replace("mm", month).replace("yyyy", year);
        },

        localizedDate(timestamp) {
            const date = new Date(timestamp);
            return this.$d(date, "dialog", "ru-RU");
        },
        timestampYear(timestamp) {
            const date = new Date(timestamp);
            return date.getFullYear();
        },
        formatUserName(name) {
            return name === "Newbie" ? this.locale("newbie", {}, true) : name;
        },
        disableBodyScroll() {
            document.body.classList.add("hidden");
        },
        enableBodyScroll() {
            document.body.classList.remove("hidden");
        },
        async showProfessionReferralModal(term) {
            await this.$apollo
                .query({
                    query: gql(getAdvertQuery),
                    variables: {
                        term: term,
                    },
                })
                .then((r) => {
                    const link = {
                        id: r.data.getAdvert.id,
                        text: r.data.getAdvert.text,
                        url: r.data.getAdvert.url,
                    };
                    this.$bus.$emit(
                        "showPersonDescriptionModal",
                        term,
                        r.data.getAdvert.description,
                        link,
                    );
                });
        },
    },
});
